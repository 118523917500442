// RU specific Fonts Family
$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;
$font--malone: 'Spectral', Times, 'Times New Roman', serif !default;
$font--lars: 'Cyrillic Lars Malone', Helvetica, Arial, sans-serif !default;
$font--lars-bold: $font--lars;

// RU Specific Translations
$reviews_helpful_yes: 'Да' !default;
$reviews_helpful_no: 'Нет' !default;
